<template>
    <div>
        <el-card class="management-report-card-box">
            <el-form>
                <div class="flex flex-row justify-between items-center mb-2">
                    <h2 class="text-base font-semibold mb-2">{{ title }}</h2>
                    <div class="flex items-center">
                        <!-- <el-tag type="danger">Det har skett ändringar i bokföringen</el-tag> -->
                        <RefreshButton
                            :card-title="title"
                            :annual-report-id="annualReportId"
                            :refresh-data="refreshData"
                            loading-key="loading-multiYearOverView"
                            :success-message="`Hämtat ny data till flerårsöversikt`"
                            :disable-button="showEditButton"
                        />
                        <InfoButton :content="infoContent" />
                        <el-switch v-model="localToggleValue" active-color="#48c78e" inactive-color="#D1D5DB" @change="onToggleChange" />
                    </div>
                </div>
                <div v-if="toggleValue && yearlyOverviewData.length > 0" v-loading="$waiting.is('loading-multiYearOverView')">
                    <el-table :data="yearlyOverviewData" size="mini" :show-header="false">
                        <el-table-column prop="description" width="300">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.description" disabled />
                            </template>
                        </el-table-column>
                        <el-table-column v-for="(value, index) in yearlyOverviewData[0].amounts" :key="index" prop="amounts" width="150" editable>
                            <template slot-scope="scope">
                                <!-- <p>{{ scope.row }}</p> -->
                                <el-input
                                    v-if="scope.row.description != 'Soliditet'"
                                    v-model.number="scope.row.amounts[index]"
                                    type="number"
                                    :value="scope.row.amounts[index] || 0"
                                    :disabled="scope.row.description == 'Flerårsöversikt (Tkr)' || inputDisabled"
                                />
                                <el-input v-else v-model.number="scope.row.solvency[index]" type="number" :value="scope.row.solvency[index] || 0" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                    </el-table>
                    <div v-show="showComment">
                        <h2 class="text-base font-semibold mb-2 mt-4">Kommentar:</h2>
                        <el-input type="textarea" :disabled="inputDisabled" v-model="localInputText" rows="2" class="break-normal custom-textarea" />
                    </div>
                </div>
            </el-form>
            <CardEditButtons
                :toggle-value="toggleValue"
                :show-edit-button="showEditButton"
                :toggle-edit="toggleEdit"
                :close-modal="closeModal"
                :create="create"
                :show-comment-button="showCommentButton"
                :show-comment="showComment"
                @toggle-comment="handleToggleComment"
            />
        </el-card>
    </div>
</template>
<script>
import { Switch } from "element-ui";
import Api from "../annualReport.api";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        inputText: {
            type: String,
            default: "",
        },
        yearlyOverviewData: {
            type: Array,
            default: () => [],
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
        showComment: {
            type: Boolean,
        },
        annualReportId: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            updatedYearlyOverviewData: [],
            inputDisabled: true,
            showEditButton: true,
            localToggleValue: this.toggleValue,
            localInputText: this.inputText,
            showCommentButton: true,
            infoContent: `För att uppfylla kraven på en rättvisande översikt enligt 6 kap. 1 § första stycket ÅRL ska företaget för räkenskapsåret och de tre föregående åren lämna uppgift om <br />a) nettoomsättning, <br />b) resultat efter finansiella poster, och <br />c) soliditet (justerat eget kapital i procent av balansomslutning). <br /><br />Varierar nettoomsättningen mer än 30 procent mellan åren ska företaget kommentera detta (se egen rubrik i trädet till vänster). Företaget får ange ytterligare nyckeltal. <br /><br /> K2 (BFNAR 2016:10) kapitel 4 punkt 9 och kapitel 5 punkt 5.`,
            initialInputText: "",
            isInitialInputTextSet: false,
            initialYearlyOverviewData: [],
            isInitialYearlyOverviewDataSet: false,
            newRows: [],
        };
    },
    components: {
        "el-switch": Switch,
        CardEditButtons: () => import("./components/CardEditButtons.vue"),
        InfoButton: () => import("./components/InfoButton.vue"),
        RefreshButton: () => import("./components/RefreshData.vue"),
    },
    watch: {
        inputText(newVal) {
            this.localInputText = newVal;
            if (!this.isInitialInputTextSet) {
                this.initialInputText = newVal;
                this.isInitialInputTextSet = true;
            }
        },
        localInputText(newVal) {
            this.$emit("update:text", newVal);
        },
        toggleValue(newVal) {
            this.localToggleValue = newVal;
        },
        showComment(newVal) {
            this.$emit("update:showYearlyOverviewComment", newVal);
        },
    },
    methods: {
        async refreshData() {
            const refreshMultiYearOverview = await Api.getManagementReportBooking(this.annualReportId, "multiYearOverview");
            this.newRows = refreshMultiYearOverview;
            this.$emit("update:row", this.newRows);
        },
        handleToggleComment(newShowComment) {
            this.showComment = newShowComment;
        },
        toggleEdit() {
            this.inputDisabled = !this.inputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        onToggleChange(newVal) {
            const eventData = {
                source: 5,
                value: newVal,
            };
            this.$emit("toggle-change", eventData);
        },
        emptyValues(index) {
            if (index == 3) {
                return this.updatedYearlyOverviewData[index].solvency.some(amount => amount === null || amount === "");
            } else {
                return this.updatedYearlyOverviewData[index].amounts.some(amount => amount === null || amount === "");
            }
        },
        async create() {
            this.updatedYearlyOverviewData = this.yearlyOverviewData;
            if (this.emptyValues(1) || this.emptyValues(2) || this.emptyValues(3)) {
                this.$notify.error({ title: "Fyll in alla fält i flerårsöversikten" });
            } else {
                this.$emit("update-yearly-overview-data", this.updatedYearlyOverviewData);
                this.$emit("create");
                this.$notify.success({ title: 'Sparat "Flerårsöversikt"' });
                this.toggleEdit();
            }
        },
        convertToNumber(row, field) {
            row[field] = Number(row[field]);
        },
        closeModal() {
            const load = "loading-multiYearOverView";
            this.$emit("close", { load: load, inputTextNewValue: this.inputTextNewValue });
            this.toggleEdit();
        },
    },
    computed: {
        inputTextNewValue() {
            return this.initialInputText != this.localInputText;
        },
    },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>
